import { USE_MOCK } from "common/config/constants";
import { ENDPOINTS } from "common/config/endpoints";
import {
  fetchMockLivenessCheckStatus,
  makeAuthorizedRequestWithHeadersAndPayload,
  makeRequestWithFormData,
  setMockLivenessCheckStatus,
} from "common/services";
import {
  businessOwnerVerificationDetails,
  businessVerificationTaskDetails,
  guarantorVerificationDetails,
} from "modules/verificationTasks/historyTasks/service/mock";
import {
  IBusinessVerificationReqBody,
  IEmployeeVerificationRequestBody,
  IGuarantorVerificationReqBody,
  ISilentBusinessVerificationReqBody,
} from "../types";
import {
  mockBusinessAddressVerificationTaskDetails,
  mockBusinessOwnerVerificationTaskDetails,
  mockBusinessVerificationPhysicalStructures,
  mockBusinessVerificationTaskDetails,
  mockEmployeeVerificationTaskDetails,
  mockFVConfigResponse,
  mockGuarantorVerificationTaskDetails,
  mockIndividualVerificationTaskDetails,
  mockTaskSummary,
  mockVerificationLocationValidationConfig,
  mockVerificationTasksCancelledData,
  mockVerificationTasksData,
} from "./mock";
import { IMediaCapturedToPost } from "../../../../common/types/components";
import { ILivenessCheckResponse } from "../types/tasks-model";
import { IAddressVerificationRequestBody } from "../pages/addressVerification/individualAddressVerification/types";
import {
  businessCategories,
  businessSubCategories,
} from "../pages/addressVerification/businessAddressVerification/constants";
import { generatePostRequestBodyForCustomSearch } from "common/utils/helpers/verification-task-utils";

export const getPendingVerificationTasks = (
  queryParams: any,
  pagination = {
    current: 0,
    pageSize: 10,
  }
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.pending;

  /** changing to post to accommodate a larger request body */

  let requestParams = {
    ...queryParams,
    pageNumber: pagination?.current ? pagination.current - 1 : 0,
    pageSize: pagination?.pageSize ? pagination.pageSize : 10,
  };

  let searchRequestBody = generatePostRequestBodyForCustomSearch(requestParams);

  console.log(searchRequestBody);

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockVerificationTasksData);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    searchRequestBody
  );
};

export const getCancelledVerificationTasks = (
  queryParams: any,
  pagination = {
    current: 0,
    pageSize: 10,
  }
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.cancelled;
  /** changing to post to accommodate a larger request body */

  let requestParams = {
    ...queryParams,
    pageNumber: pagination?.current ? pagination.current - 1 : 0,
    pageSize: pagination?.pageSize ? pagination.pageSize : 10,
  };

  let searchRequestBody = generatePostRequestBodyForCustomSearch(requestParams);

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockVerificationTasksCancelledData);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    searchRequestBody
  );
};

export const getPendingVerificationTaskDetails = (
  id: number | string
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.pendingTaskDetails;

  if (USE_MOCK) {
    const mock = {
      1: mockBusinessVerificationTaskDetails,
      6: mockBusinessOwnerVerificationTaskDetails,
      10: mockEmployeeVerificationTaskDetails,
      8: mockGuarantorVerificationTaskDetails,
      29: mockIndividualVerificationTaskDetails,
      39: mockBusinessAddressVerificationTaskDetails,
    };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mock[id as 1 | 6 | 8]);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url.replace("{id}", `${id}`)
  );
};

export const postBusinessOwnerVerification = (data: {
  verificationTaskId: number;
  verificationData: {
    verificationLocation: { latitude: number; longitude: number };
    outsideResidenceImages: IMediaCapturedToPost[];
    businessOwnerLivenessCheck: ILivenessCheckResponse | null;
    neighbourConfirmsBusinessOwnersAddress: boolean;
    insideResidenceImages: IMediaCapturedToPost[];
    fvoLivenessCheck: ILivenessCheckResponse;
    fvoComment?: string;
    isBusinessOwnerLivingAtAddress: boolean;
  };
}): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.submitVerifications;

  if (USE_MOCK) {
    const mock = { message: "verification successful" };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mock);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    data
  );
};

export const postEmployeeVerification = (
  data: IEmployeeVerificationRequestBody
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.submitVerifications;

  if (USE_MOCK) {
    const mock = { message: "verification successful" };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mock);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    data
  );
};

export const postUploadFile = (file: File): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.uploadFile;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          reference: Date.now(),
          name: "ENGINEERING-CAREER-LADDER-T9Kk3e",
          extension: "jpg",
        });
      }, 1000);
    });
  }

  return makeRequestWithFormData(endpoint.method, endpoint.url, { file }, true);
};

export const getLivenessCheckConfig = (
  isFvoVerification: boolean,
  id?: number
): Promise<any> => {
  let endpoint: { url: string; method: string };
  if (isFvoVerification) {
    endpoint = ENDPOINTS.verificationTasks.initiateFvoLivenessCheck;
  } else {
    endpoint = {
      ...ENDPOINTS.verificationTasks.initiateVerificationLivenessCheck,
      url: ENDPOINTS.verificationTasks.initiateVerificationLivenessCheck.url,
    };
  }

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockFVConfigResponse);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    `${endpoint.url}`,
    { verificationTaskId: id }
  );
};

export const performLivenessCheck = (reference: string) =>
  new Promise((resolve, reject) => {
    resolve({
      reference: "daoono-2k1mdad-2101932",
    });
  });

export const getBusinessPhysicalStructures = (): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.businessPhysicalStructures;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockBusinessVerificationPhysicalStructures);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url
  );
};

export const submitBusinessVerification = (
  reqBody: IBusinessVerificationReqBody
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.submitVerifications;

  if (USE_MOCK) {
    console.log("BUSINESS VERIFICATION DATA TO SUBMIT", reqBody);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          message: "Verification completed!",
        });
      }, 10000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    reqBody
  );
};

export const submitSilentBusinessVerification = (
  reqBody: ISilentBusinessVerificationReqBody
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.submitVerifications;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          message: "Verification completed!",
        });
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    reqBody
  );
};

export const submitGuarantorVerification = (
  reqBody: IGuarantorVerificationReqBody
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.submitVerifications;
  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          message: "Verification completed!",
        });
      }, 6000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    reqBody
  );
};

export const submitAddressVerification = (
  reqBody: IAddressVerificationRequestBody | IBusinessVerificationReqBody
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.submitVerifications;
  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          message: "Verification completed!",
        });
      }, 6000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    reqBody
  );
};

export const getLivenessCheckVerificationStatus = (
  reference: string
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.liveNessCheckVerificationStatus;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      const defaultLivenessCheckStatus =
        Number(fetchMockLivenessCheckStatus()) || 0;

      const status = ["PENDING", "FAILED", "PENDING", "SUCCESS"];

      setMockLivenessCheckStatus(
        `${(defaultLivenessCheckStatus + 1) % status.length}`
      );

      setTimeout(() => {
        resolve({ status: status[defaultLivenessCheckStatus] });
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    `${endpoint.url}/${reference}`
  );
};

export const getTaskDetails = (
  id: number,
  type: "BUSINESS" | "GUARANTOR" | "BUSINESSOWNER"
): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.history;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          type === "BUSINESS"
            ? businessVerificationTaskDetails
            : type === "GUARANTOR"
            ? guarantorVerificationDetails
            : businessOwnerVerificationDetails
        );
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    `${endpoint.url}/${id}`
  );
};

export const getTaskSummary = (): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.taskSummary;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockTaskSummary);
      }, 3000);
    });
  }
  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url
  );
};

export const getVerificationLocationValidationConfig = (): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.config;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockVerificationLocationValidationConfig);
      }, 3000);
    });
  }
  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url
  );
};

export const getBusinessCategories = (): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.businessCategories;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(businessCategories);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    `${endpoint.url}`
  );
};

export const getBusinessSubCategories = (id: number | string): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.businessCategories;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(businessSubCategories);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    `${endpoint.url}/${id}/types`
  );
};

export const rejectVerificationTask = (requestBody: {
  assigneeEmail?: string;
  reason: string;
  clientVerificationReference: string;
}): Promise<any> => {
  let endpoint = ENDPOINTS.verificationTasks.rejectTask;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ message: "Task re-assigned successfully" });
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    `${endpoint.url}`,
    requestBody
  );
};
