import { combineReducers } from "redux";
import sessionReducer from "../../modules/auth/reducer";
import verificationLocationValidation from "../../modules/verificationTasks/pendingTasks/reducer/verification-location-validation-reducer";
import sidebarReducer from "../../modules/sidebar/reducer";
import walletReducer from "../../modules/wallet/reducer";
import dashboardReducer from "../../modules/dashboard/reducer";
import verificationTaskReducer from "../../modules/verificationTasks/reducer"

export default combineReducers({
  user: sessionReducer,
  verificationLocationValidation,
  sidebar: sidebarReducer,
  wallet: walletReducer,
  dashboard: dashboardReducer,
  verificationTasks: verificationTaskReducer
});
