import { IBusinessCategories } from "../pendingTasks/pages/addressVerification/businessAddressVerification/types";
import { IBusinessVerificationPhysicalStructures } from "../pendingTasks/types/tasks-model";

export const BUSINESS_STRUCTURES_FETCHED = "BUSINESS_STRUCTURES_FETCHED";
export const IS_FETCHING_BUSINESS_STRUCTURES = "IS_FETCHING_BUSINESS_STRUCTURES";
export const BUSINESS_CATEGORIES_FETCHED = "BUSINESS_CATEGORIES_FETCHED";
export const IS_FETCHING_BUSINESS_CATEGORIES = "IS_FETCHING_BUSINESS_CATEGORIES";

export const BUSINESS_CATEGORIES_FETCH_FAILED = "BUSINESS_CATEGORIES_FETCH_FAILED";
export const BUSINESS_STRUCTURES_FETCH_FAILED = "BUSINESS_STRUCTURES_FETCH_FAILED";

export interface VerificationStoreModel {
    isFetchingBusinessStructures: boolean;
    businessStructures: IBusinessVerificationPhysicalStructures[];
    isFetchingBusinessCategories: boolean;
    businessCategories: IBusinessCategories[];
    businessStructureFetchError: any;
    businessCategoriesFetchError: any;
}