import { ReduxActionModel } from "common/types";
import {
  FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG,
  FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED,
  LOCATION_VALIDATION_CONFIG_FETCHED,
  UPDATE_VERIFICATION_LOCATION_ADDRESS,
  UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES,
  UPDATE_VERIFICATION_LOCATION_COORDINATES,
} from "../actions/types";

const initialState = {
  verificationLocationValidationConfig: null,
  verificationLocationValidationConfigError: null,
  isFetchingLocationValidationConfig: false,
  verificationAddress: null,
  verificationTaskLocationCoordinates: null,
  verificationOfficerLocationCoordinates: null,
};

export default (state = initialState, action: ReduxActionModel) => {
  switch (action.type) {
    case FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED:
      state = {
        ...state,
        isFetchingLocationValidationConfig: false,
        verificationLocationValidationConfigError: action.payload,
      };
      break;

    case FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG:
      state = {
        ...state,
        isFetchingLocationValidationConfig: true,
        verificationLocationValidationConfigError: null,
      };
      break;
    case LOCATION_VALIDATION_CONFIG_FETCHED:
      state = {
        ...state,
        verificationLocationValidationConfig: action.payload,
        verificationLocationValidationConfigError: null,
        isFetchingLocationValidationConfig: false,
      };
      break;
    case UPDATE_VERIFICATION_LOCATION_ADDRESS:
      state = {
        ...state,
        verificationAddress: action.payload,
      };
      break;
    case UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES:
      state = {
        ...state,
        verificationTaskLocationCoordinates: action.payload,
      };
      break;
    case UPDATE_VERIFICATION_LOCATION_COORDINATES:
      state = {
        ...state,
        verificationOfficerLocationCoordinates: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};
