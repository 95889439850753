import { message } from "antd";
import { getFromFileService } from "common/services/request/fileService";
import { useEffect, useState } from "react";

interface IuseFileServiceDownloadParams {
  fileReference: string | undefined;
  useCache?: boolean;
}

interface FileServiceData {
  contentType: string;
  base64String: string;
}

export const useFileServiceDownload = ({
  fileReference,
  useCache = false,
}: IuseFileServiceDownloadParams) => {
  const [isFetchingImageFromFileService, setIsFetchingImageFromFileService] =
    useState(!!fileReference);
  const [fileServiceDownloadData, setFileServiceDownloadData] =
    useState<FileServiceData>({
      contentType: "",
      base64String: "",
    });
    
  const [referenceToDataMap, setReferenceToDataMap] = useState<
    { [key: string]: FileServiceData }[]
  >([]);

  const fetchApi = () => {
    setIsFetchingImageFromFileService(true);
    if (useCache && fileReference) {
      const cachedData = referenceToDataMap.find((item) => item[fileReference]);
      if (cachedData) {
        setFileServiceDownloadData(cachedData[fileReference]);
        setIsFetchingImageFromFileService(false);
        return;
      }
    }
    getFromFileService(fileReference || "")
      .then((res) => {
        setFileServiceDownloadData({
          contentType: res.contentType,
          base64String: res.base64String,
        });
        setIsFetchingImageFromFileService(false);
        if (useCache && fileReference) {
          setReferenceToDataMap([
            ...referenceToDataMap,
            {
              [fileReference]: {
                contentType: res.contentType,
                base64String: res.base64String,
              },
            },
          ]);
        }
      })
      .catch((e) => {
        message.error(e);
      });
  };
  useEffect(() => {
    if (!fileReference) return;
    fetchApi();
  }, [fileReference]);

  return {
    fetchApi,
    fileServiceDownloadData,
    isFetchingImageFromFileService,
  };
};
