export const API_VERIFICATION_TASK_MAP: any = {
  BUSINESS: "BUSINESS_VERIFICATION",
  BUSINESS_OWNER: "BUSINESS_OWNER_VERIFICATION",
  GUARANTOR: "LOAN_GUARANTOR_VERIFICATION",
  EMPLOYEE: "EMPLOYEE_VERIFICATION",
  INDIVIDUAL_ADDRESS: "INDIVIDUAL_ADDRESS_VERIFICATION",
  BUSINESS_ADDRESS: "BUSINESS_ADDRESS_VERIFICATION",
  SILENT_BUSINESS_VERIFICATION: "SILENT_BUSINESS_VERIFICATION",
};

export const TASK_STATUSES = {
  DUE_SOON: "due_soon",
  PENDING: "pending",
  OVERDUE: "overdue",
};

export const taskStatusMap = {
  [TASK_STATUSES.DUE_SOON]: {
    text: "DUE SOON",
    bgColor: "bg-tag-orange-bg",
    color: "text-tag-orange",
  },
  [TASK_STATUSES.PENDING]: {
    text: "PENDING",
    bgColor: "bg-tag-orange-bg",
    color: "text-tag-orange",
  },
  [TASK_STATUSES.OVERDUE]: {
    text: "OVERDUE",
    bgColor: "bg-tag-red-bg",
    color: "text-tag-red",
  },
};
