export interface RoutesProps {
  setCurrentPageIndex?: Function;
}

export interface RouteConfig {
  path: string;
  access: "everyone" | "guest-only" | "customer-only";
  exact: boolean;
  Component: any;
}

export enum ROUTES {
  LOGIN = "/login",
  LOGIN_WITH_MONIEPOINT = "/login-with-moniepoint",
  PASSWORD_RECOVERY = "/recovery/password",
  USERNAME_RECOVERY = "/recovery/username",
  REDIRECT = "/redirect",
  DEVICE_REG = "/device_reg",
  ACCOUNT_ACTIVATION = "/account/activation",
  // VERIFICATION
  VERIFICATION_TASKS = "/tasks",
  PENDING_VERIFICATION_TASKS_VIEW = "/tasks?activeTab=pending",
  COMPLETED_VERIFICATION_TASKS_VIEW = "/tasks?activeTab=completed",
  CANCELED_VERIFICATION_TASKS_VIEW = "/tasks?activeTab=canceled",
  VERIFICATION_TASKS_PENDING = "/tasks/pending-tasks/:id",
  COMPLETED_VERIFICATION_TASKS_DETAILS_VIEW_PATH = "/tasks/completed-tasks/",
  COMPLETED_VERIFICATION_TASKS_DETAILS_VIEW = "/tasks/completed-tasks/:id",
  // AUTH
  LOGIN_GSUITE = "/login/gsuite",
  // WALLET
  WALLET = "/wallet",
  // DASHBOARD
  DASHBOARD = "/dashboard",
}
