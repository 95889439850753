import { ReduxActionModel } from "common/types";
import {
  BUSINESS_STRUCTURES_FETCHED,
  IS_FETCHING_BUSINESS_STRUCTURES,
  BUSINESS_CATEGORIES_FETCHED,
  IS_FETCHING_BUSINESS_CATEGORIES,
  BUSINESS_CATEGORIES_FETCH_FAILED,
  BUSINESS_STRUCTURES_FETCH_FAILED,
} from "../actions/types";

const initialState = {
  isFetchingBusinessStructures: false,
  businessStructures: [],
  isFetchingBusinessCategories: false,
  businessCategories: [],
  businessStructureFetchError: null,
  businessCategoriesFetchError: null,
};

export default (state = initialState, action: ReduxActionModel) => {
  switch (action.type) {
    case IS_FETCHING_BUSINESS_STRUCTURES:
      state = {
        ...state,
        isFetchingBusinessStructures: action.payload,
      };
      break;
    case BUSINESS_STRUCTURES_FETCHED:
      state = {
        ...state,
        isFetchingBusinessStructures: false,
        businessStructures: action.payload,
        businessStructureFetchError: null,
      };
      break;
    case BUSINESS_STRUCTURES_FETCH_FAILED:
      state = {
        ...state,
        isFetchingBusinessStructures: false,
        businessStructureFetchError: action.payload,
      };
      break;
    case IS_FETCHING_BUSINESS_CATEGORIES:
      state = {
        ...state,
        isFetchingBusinessCategories: action.payload,
      };
      break;
    case BUSINESS_CATEGORIES_FETCHED:
      state = {
        ...state,
        isFetchingBusinessCategories: false,
        businessCategories: action.payload,
        businessCategoriesFetchError: null,
      };
      break;
    case BUSINESS_CATEGORIES_FETCH_FAILED:
      state = {
        ...state,
        isFetchingBusinessCategories: false,
        businessCategoriesFetchError: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};
